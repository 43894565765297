<template>
  <!-- 绑定邮箱 -->
  <div id="house-login" class="layui-fulid">
    <div class="layui-form" style="transform: translateY(-100px)">
<!--      <p>请绑定用户名和邮箱</p>-->
      <div>
        <div class="infor-top">
          <img
            v-if="userInfo && userInfo.avatar"
            :src="imageUrl"
            class="logo"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <br />
          <span
            @click="$refs['avatar-uploader-icon'].click()"
            style="cursor: pointer; text-decoration: underline"
            >更换头像</span
          >

          <el-upload
            :before-upload="beforeAvatarUpload"
            :headers="headers"
            :on-success="handleAvatarSuccess"
            :show-file-list="false"
            :action="uploadUrl"
            class="avatar-uploader"
          >
            <i
              class="el-icon-plus avatar-uploader-icon upload-icon"
              ref="avatar-uploader-icon"
            ></i>
          </el-upload>
        </div>
        <div class="layui-input-block login">
          <h2><img src="@/assets/img/icon-user.png" />用户名</h2>
          <el-input
            v-model="form.username"
            class="layui-input"
            lay-verify="required"
            placeholder="请输入用户名"
            required
            type="text"
          />
        </div>
        <div class="layui-input-block login">
          <h2><img src="@/assets/img/email.png" />邮箱</h2>
          <el-input
            v-model="form.email"
            class="layui-input"
            lay-verify="required"
            placeholder="请输入邮箱"
            required
          />
        </div>
<!--        <div class="layui-input-block login">-->
<!--          <h2><img src="@/assets/img/code.png" />验证码</h2>-->
<!--          <el-input-->
<!--            v-model="form.code"-->
<!--            class="layui-input code"-->
<!--            lay-verify="required"-->
<!--            placeholder="请输入验证码"-->
<!--            required-->
<!--          />-->
<!--          <el-button-->
<!--            class="sendVerify"-->
<!--            :disabled="isSend"-->
<!--            @click="sendVerify"-->
<!--            >{{ isSend ? `${countDown}秒后再次发送` : "发送验证码" }}</el-button-->
<!--          >-->
<!--        </div>-->
<!--        <div class="layui-input-block login">-->
<!--          <h2><img src="@/assets/img/icon-password.png" />登录密码</h2>-->
<!--          <el-input-->
<!--              v-model="form.password"-->
<!--              class="password"-->
<!--              lay-verify="required"-->
<!--              placeholder="请设置登录密码"-->
<!--              required-->
<!--              type="password"-->
<!--              v-on:input="inputFunc()"-->
<!--          />-->
<!--          <div-->
<!--              v-show="passwordTipDisplay"-->
<!--              ref="passwordTip"-->
<!--              class="passwordTip"-->
<!--              style="display: flex"-->
<!--          >-->
<!--            <div class="dot"></div>-->
<!--            长度6-16位字符,可包含数字，字母(区分大小写)-->
<!--          </div>-->
<!--        </div>-->
        <div class="else" style="margin: 25px 0 10px 0">
          <div
              class="le"
              style="
            text-align: center;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          "
          >
            <input
                v-model="agree"
                checked
                style="margin-right: 4px"
                type="checkbox"
            />
            我已阅读并同意
            <router-link :to="{ path: '/question', query: { type: 6 } }"
            >《用户协议》
            </router-link>
          </div>
        </div>
      </div>

      <button
        class="layui-btn login"
        style="margin-top: 40px"
        lay-filter="user-login"
        @click="register"
      >
        注册
      </button>
    </div>
  </div>
</template>

<script>
import { sendVerifyEmail } from "@/service/refer";
import { mapState } from "vuex";
import {getIn, validEmail, validPwd} from "@/utils/index";
import { bindEmail } from "@/service/index";
import {Loading} from "element-ui";
export default {
  data() {
    return {
      form: {},
      code: "",
      countDown: 60,
      isSend: false,
      agree: false,
      imageUrl: "",
      headers: {},
      passwordTipDisplay: false,
      passwordCheckDisplay: false,
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload?isPublic=true",
    };
  },
  created() {},
  methods: {
    register() {
      if (this.form.username == "") {
        return this.$message.warning("请输入用户名!");
      }
      if (this.form.email == "") {
        return this.$message.warning("请输入邮箱!");
      }
      // if (this.form.code == "") {
      //   return this.$message.warning("请输入验证码!");
      // }
      // if (this.form.password == "") {
      //   return this.$message.warning("请输入密码!");
      // }

      if (!this.checkUserInput()) return;
      if (
          !/^[A-Za-z0-9-_\u4e00-\u9fa5]{3,12}$/.test(this.form.username) ||
          !isNaN(+this.form.username)
      ) {
        this.$message.closeAll();
        this.$message.error("用户名仅支持中英文、数字和下划线，且不能为纯数字");
        return;
      }
      // 密码长度6-16位字符,可包含数字，字母(区分大小写)
      // if (this.form.password.length == 0) {
      //   this.$message.closeAll();
      //   this.$message.error("请设置登录密码");
      //   return;
      // }
      // if (
      //     !this.form.password ||
      //     this.form.password.length < 6 ||
      //     !validPwd(this.form.password) ||
      //     /^\d{1,}$/.test(this.form.password) ||
      //     /^[a-zA-Z]*$/.test(this.form.password)
      // ) {
      //   this.$message.closeAll();
      //   this.$message.error(
      //       "密码长度为6-16字符，必须包含数字，字母（区分大小写）"
      //   );
      //   return;
      // }
      if (!this.agree) {
        this.$message.closeAll();
        this.$message.error("请选中用户协议后进行注册");
        return;
      }

      bindEmail({
        ...this.form,
      }).then((res) => {
        if (res.success) {
          this.$message.success("注册成功!");
          setTimeout(() => {
            window.location.href = "/";
            this.isLoading = false;
          }, 1500);
        } else {
          this.$message.closeAll();
          this.$message.error(res.message);
        }
      });
    },
    sendVerify() {
      //ajax successed after
      if (!this.form.email || this.form.email == "") {
        return this.$message.error("请输入邮箱!");
      }
      if (!validEmail(this.form.email)) {
        this.$message.closeAll();
        this.$message.error("请输入合法邮箱");
        return;
      }
      // sendVerifyEmail(this.form.email).then((res) => {
      //   if (res.success) {
      //     this.$message.success("验证码已发送，请注意查收");
      //     this.isSend = true;
      //     this.sendInterval = setInterval(() => {
      //       this.countDown = this.countDown - 1;
      //       if (this.countDown == 0) {
      //         this.isSend = false;
      //         this.countDown = 60;
      //         clearInterval(this.sendInterval);
      //       }
      //     }, 1000);
      //   } else {
      //     this.$message.error(res.message);
      //   }
      // });
    },
    // passwordInputBlur() {
    //   if (!this.checkPasswordInput()) {
    //     this.$message.closeAll();
    //     this.$message.error("密码设置不符合规则");
    //   }
    // },
    // passwordInputFocus() {
    //   if (this.checkPasswordInput()) {
    //     this.passwordCheckDisplay = true;
    //     this.passwordTipDisplay = false;
    //   } else {
    //     this.passwordCheckDisplay = false;
    //     this.passwordTipDisplay = true;
    //   }
    // },
    // inputFunc() {
    //   if (this.checkPasswordInput()) {
    //     this.passwordCheckDisplay = true;
    //     this.passwordTipDisplay = false;
    //   } else {
    //     this.passwordCheckDisplay = false;
    //     this.passwordTipDisplay = true;
    //   }
    // },
    // checkPasswordInput() {
    //   if (
    //       this.form.password.length == 0 ||
    //       this.form.password.length < 6 ||
    //       this.form.password.length > 16 ||
    //       !validPwd(this.form.password) ||
    //       /^\d{1,}$/.test(this.form.password) ||
    //       /^[a-zA-Z]*$/.test(this.form.password)
    //   ) {
    //     return false;
    //   }
    //   return true;
    // },
    checkUserInput() {
      if (this.form.username.length < 3) {
        this.$message.closeAll();
        this.$message.error("用户名不能小于3位字符");
        return false;
      }
      if (this.form.username.length > 12) {
        this.$message.closeAll();
        this.$message.error("用户名不能大于12位字符");
        return false;
      }
      return true;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.form.avatar = res.result.objectKey;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    async handleAvatarSuccess(e) {
      const avatar = getIn(e, ["result", "accessibleUrl"]);
      const objectKey = getIn(e, ["result", "objectKey"]);
      const message = getIn(e, ["message"]) || "系统繁忙，请稍后再试";
      if (avatar && objectKey) {
        this.currentAvatar = avatar;
        this.objectKey = objectKey;
      } else {
        this.$message.closeAll();
        this.$message.error(message);
      }
    },
  },
  async created() {
    await this.$store.dispatch("queryUserInfo");
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {
    this.headers = {
      "X-Access-Token": window.localStorage.getItem("CSON_PAGE_TOKEN"),
    };
  },
  watch: {
    userInfo: {
      handler() {
        this.imageUrl = this.userInfo.avatar;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
::v-deep .el-input-group__append {
  line-height: 42px;
  height: 42px;
}
::v-deep .el-select input {
  height: inherit !important;
  margin-bottom: 0 !important;
}
::v-deep .el-input__inner {
  margin-bottom: 0;
}
.layui-input.code {
  width: 220px;
  margin-right: 12px;
}
.layui-input-block {
  text-align: left;
}
.sendVerify {
  width: calc(100% - 232px) !important;
  display: inline-block;
  vertical-align: top;
  margin-top: 0 !important;
  background: transparent !important;
  padding: 0 !important;
  text-align: center;
  color: #fff;
  font-size: 14px !important;
}
::v-deep .el-upload {
  display: none !important;
}
.logo {
  border-radius: 50%;
  width: 120px;
  height: 120px;
}
</style>
